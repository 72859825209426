import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { CallToActionButton } from '../components/button';
import { Layout } from '../components/layout';
import { OwnUpLink } from '../components/links';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../components/typography';
import BinocularsWithDots from '../images/404/binoculars-with-green-dots.svg';

const HeadHomeButton = () => (
  <OwnUpLink link="/" label="Own Up Home Page">
    <CallToActionButton>LET&apos;S HEAD HOME</CallToActionButton>
  </OwnUpLink>
);

const FourOhFourTextStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const FourOhFourImageStyle = createOwnUpStyle({
  margin: 'auto',
  maxWidth: '338px'
});

const FourOhFourWrapperStyle = createOwnUpStyle({
  margin: 30,
  paddingBottom: 50,
  variants: {
    mediumAndDown: {
      paddingBottom: 25
    }
  }
});

const FourOhFourWrapper = createOwnUpComponent('div', FourOhFourWrapperStyle);

const ContentWrapper = createOwnUpComponent('div', FourOhFourTextStyle);
const ImageWrapper = createOwnUpComponent('div', FourOhFourImageStyle);

export const FourOhFour = () => {
  return (
    <Layout>
      <FourOhFourWrapper>
        <ImageWrapper>
          <img
            src={BinocularsWithDots}
            alt="binoculars on a solid green background and green dots behind it"
          />
        </ImageWrapper>
        <ContentWrapper>
          <ResponsiveSectionHeader style={{ marginBottom: 0 }} level={1}>
            Whoops!
          </ResponsiveSectionHeader>
          <ResponsivePrimaryText>
            <p>The page you were looking for has moved or doesn&apos;t exist.</p>
          </ResponsivePrimaryText>
          <HeadHomeButton />
        </ContentWrapper>
      </FourOhFourWrapper>
    </Layout>
  );
};

export default FourOhFour;
